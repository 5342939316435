import React, { Component } from 'react';
import "./Method.scss"

export default class Method extends Component {
  render() {
    return (
      <div className="Method">
        <div className="container">
          <h2 className="title">
            How It Works
          </h2>
          <p className="text">
            Our solution combines Truchet tiles and the mathematical method of “surface filling” for endless creation of karakusa motifs. This enabled the generation of spiral designs surpassing what even the most accomplished craftspeople could imagine. The tool also facilitated the participation of a wider circle of individuals in the process.
          </p>

          <div className="method-1">
            <img src="images/illustrator.jpg" alt=""/>
            <img className="arrow" src="images/right-arrow.svg" alt=""/>
            <img src="images/original-illust.jpg" alt=""/>
            <img className="arrow" src="images/right-arrow.svg" alt=""/>
            <img src="images/box.jpg" alt=""/>
            <p className="caption">
              Original illustrations from 1000 years of brand history
            </p>
          </div>

          <div className="down-arrow">
            <img src="images/down-arrow.svg" alt=""/>
          </div>

          <div className="method-2">
            <img src="images/elems.jpg" alt=""/>
            <p className="caption">Extracted pattern components</p>
          </div>

          <div className="down-arrow">
            <img src="images/down-arrow.svg" alt=""/>
          </div>

          <div className="method-3">
            <video 
              src="videos/tiling.mp4" 
              ref="tiling_video"
              muted={true}
              playsInline={true}
              autoPlay={true}
              loop={true}
              ></video>
            <p className="caption">System developed to create new SHISEIDO KARAKUSA patterns</p>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import "./Exhibition.scss"

export default class Exhibition extends Component {
  render() {
    return (
      <div className="Exhibition">
        <div className="container">
          <h2 className="title">Exhibition at Ginza</h2>
          <p className="desc">
            We also used this innovative design tool to create visual installations incorporating environmental data influencing plant life, such as sunshine, rainfall, and CO2 emission quantities, along with city congestion. We exhibited it at the entrance of the Shiseido office in Ginza. Enhancing the design system enabled us to communicate the Shiseido brand identity with an emphasis on both tradition and innovation. 
          </p>

          <div className="photos clearfix">
            <div className="image single">
              <img src="images/exhibition_0.jpg" alt=""/>
            </div>

            <div className="image twin leftspace">
              <img src="images/exhibition_1.jpg" alt=""/>
            </div>

            <div className="image twin">
              <img src="images/exhibition_2.jpg" alt=""/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

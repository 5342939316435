import React, { Component } from 'react';
import "./Results.scss"

// Object.defineProperty(HTMLMediaElement.prototype, 'isPlaying', {
//   get: function(){
//       // return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
//       return !!(this.currentTime > 0 && this.readyState > 2);
//   }
// })

const sleep = function(time) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, time)
  })
}


export default class Results extends Component {
  constructor() {
    super()
    this.videos = {};
    this.isPlaying = {};

    this.state = {
      videoHeight: 0,
    }

  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll.bind(this), true)

    setTimeout(() => this.autoPlayVideos(), 300)

    let video: HTMLElement = this.refs.combinationVideoWrapper;
    let rect = video.getBoundingClientRect();
    this.setState({videoHeight: rect.height})

    for (let key in this.videos) {
      let video = this.videos[key];
      ((key, video) => {

        video.dom.addEventListener('ended', async (e) => {
          await sleep(5000)
          video.setState({hidden: true})

          await sleep(1000)
          video.dom.currentTime = 0
          video.dom.play()
          video.setState({hidden: false})
        } , false)
      })(key, video)
    }
  }

  onScroll() {
    this.autoPlayVideos();
  }

  autoPlayVideos() {
    for (let key in this.videos) {
      let video: HTMLVideoElement = this.videos[key]
      if (this.isPlaying[key]) {
        continue
      }

      // if (video.isPlaying) {
      //   continue;
      // }

      let rect = video.dom.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        video.dom.play()
        this.isPlaying[key] = true;
      }
    }
  }

  render() {
    const {videoHeight} = this.state

    return (
      <div className="Results">
        <div className="container">
          <h2 className="title">
            Generated SHISEIDO KARAKUSA Patterns
          </h2>

          <h3 className="subtitle">
            Basic SHISEIDO KARAKUSA Patterns
          </h3>

          <div className="basic-videos clearfix">
            { "ABCDEFGHI".split('').map((name, key) =>
              <div className={"element" + (key % 3 < 2 ? " leftspace" : "")} key={key}>
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos[name] = node} 
                    src={`videos/${name}.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">{name}</p>
              </div>
              )
            }
          </div>

          <h3 className="subtitle">
            Patterns Generated by Combination System
          </h3>

          <div className="combination-videos">
            <div className="group clearfix">
              <div className="element">
                <div className="video-wrapper"  ref="combinationVideoWrapper">
                  <Video 
                    ref={node => this.videos["A_combine"] = node} 
                    src={`videos/A.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">A</p>
              </div>

              <div className="operator" style={{ height: videoHeight }}>
                <img src="images/plus.svg" alt=""/>
              </div>

              <div className="element">
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos["B_combine"] = node} 
                    src={`videos/B.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">B</p>
              </div>

              <div className="operator" style={{ height: videoHeight }}>
                <img src="images/equal.svg" alt=""/>
              </div>

              <div className="element">
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos["AB_combine"] = node} 
                    src={`videos/AB.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">A + B</p>
              </div>

            </div>

            <div className="group clearfix">

              <div className="element twin leftspace">
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos["ADGI"] = node} 
                    src={`videos/ADGI.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">A + D + G + I</p>
              </div>

              <div className="element twin">
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos["CDEF"] = node} 
                    src={`videos/CDEF.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">C + D + E + F</p>
              </div>

            </div>

            <div className="group clearfix">

              <div className="element single">
                <div className="video-wrapper">
                  <Video 
                    ref={node => this.videos["ALL"] = node} 
                    src={`videos/ALL.mp4`}
                    muted="muted"
                  ></Video>
                </div>
                  <p className="name">A + B + C + D + E + F + G + H + I</p>
              </div>

            </div>

          </div>  


        </div>
      </div>
    )
  }
}

class Video extends Component {

  constructor() {
    super()

    this.state = {
      hidden: false
    }

  }

  render () {
    const {src, muted} = this.props;
    const {hidden} = this.state;

    let classNames = [
      "repeatable-video",
    ]

    if (hidden) {
      classNames.push('hidden')
    }

    return (
      <video className={classNames.join(' ')} src={src} muted={true} playsInline={true} ref={(node) => this.dom = node} />
    );
  }



}

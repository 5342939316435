import React from 'react';
import logo from './logo.svg';
import './App.scss';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Method from "./Method"
import Results from "./Results"
import Exhibition from "./Exhibition"


function App() {
  let basename = '/'
  if (window.location.host == "staging.smurakami.com") {
    basename = '/shiseido'
  }

  return (
    <Router basename={basename}>
      <Switch>
        <Route exact path="/">
          <Top is_animation={false}></Top>
        </Route>
        <Route path="/animation">
          <Top is_animation={true}></Top>
        </Route>
      </Switch>
    </Router>
  )
}

function Top(props) {
  let {is_animation} = props;

  const photo = (path, size='s12') => {
    return (
      <div className={"photo col " + size}>
        <img src={path} alt="Amarikko reference image"/>
      </div>
    )
  }

  return (
    <div className="App">
      <div className="container">
        <div className="main-title">The Continuously Changing Brand Identity “SHISEIDO KARAKUSA”</div>
        <div className="description">
          <p>
            Shiseido Karakusa, the spiral pattern symbolizing Shiseido, Japan’s foremost cosmetics manufacturer, has highlighted company products and advertising for close to a century. With media and society in the midst of the digital shift, we sought a similar way to update our designs and design system to supplement the handcrafting method trusted and implemented by Shiseido artists for 100 years.            
          </p>
          <p>
            Our solution combines Truchet tiles and the mathematical method of “surface filling” for endless creation of karakusa motifs. This enabled the generation of spiral designs surpassing what even the most accomplished craftspeople could imagine. The tool also facilitated the participation of a wider circle of individuals in the process. We also used this innovative design tool to create visual installations incorporating environmental data influencing plant life, such as sunshine, rainfall, and CO2 emission quantities, along with city congestion. Enhancing the design system enabled us to communicate the Shiseido brand identity with an emphasis on both tradition and innovation. 
          </p>
        </div>
        <div className="video">
          { is_animation ?
            <div className="youtube">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/5OYvWSIwUvg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            :
            <div className="youtube">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/5OYvWSIwUvg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          }
        </div>
      </div>

      <Method></Method>
      <Results></Results>
      <hr/>
      <Exhibition></Exhibition>

      <div className="container">
        <div className="footer">
          Copyright ©2020 Shiseido Co., Ltd. All rights reserved.
        </div>
      </div>

        {/* <div className="photos">
          <div className="row">
            { photo("images/01_1_ALL.jpg", "s12") }
            { photo("images/01_2_ALL_GLID.jpg", "s12") }
            { photo("images/02_A.jpg", "s12 m6") }
            { photo("images/02_B.jpg", "s12 m6") }
            { photo("images/02_D.jpg", "s12 m6") }
            { photo("images/02_E.jpg", "s12 m6") }
            { photo("images/02_H.jpg", "s12 m6") }
            { photo("images/02_I.jpg", "s12 m6") }
            { photo("images/03_1_AB.jpg", "s12 m6") }
            { photo("images/03_2_CDE.jpg", "s12 m6") }
            { photo("images/03_3_ADGI.jpg", "s12 m6") }
            { photo("images/03_4_ABFGHI.jpg", "s12 m6") }
            { photo("images/04_0.jpg", "s12") }
            { photo("images/04_1_exhibition.jpg", "s12") }
            { photo("images/04_2.jpg", "s12 m6") }
            { photo("images/04_3_environ.jpg", "s12 m6") }
           </div>
        </div> */}

      {/* </div> */}
    </div>
  );
}

export default App;
